import React from 'react'
import { Button, Paper, Popover, ButtonGroup } from '@material-ui/core'
import { Check as CheckIcon, Public as WorldIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import {
  usePopupState,
  bindTrigger,
  bindPopover
} from 'material-ui-popup-state/hooks'
import { useTranslation } from 'next-i18next'
import 'react-google-places-autocomplete/dist/index.min.css'
import { useRegion } from '../hooks/useRegion'
import { useRouter } from 'next/router'

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    boxShadow: '0px 3px 11px #08135914',
    borderRadius: '8px',
    width: '100%',
    border: '2px solid white'
  },
  button: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 'bold',
    boxShadow: 'none',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none'
    }
  },
  filterButton: {
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    fontWeight: 'bold',
    boxShadow: 'none',
    borderRadius: '8px',
    fontSize: '0.75rem'
  },
  textButton: {
    width: '100%'
  },
  expandedButtonGroup: {
    border: `2px solid ${theme.palette.primary.main}`
  },
  expandedButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      boxShadow: 'none'
    }
  },
  collapsedButton: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
      boxShadow: 'none'
    }
  },
  active: {
    border: `2px solid ${theme.palette.primary.main}`
  },
  popover: {
    marginTop: theme.spacing(2)
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    minWidth: 240,
    padding: theme.spacing(3)
  },
  langMenuItem: {
    backgroundColor: 'white',
    display: 'flex',
    padding: theme.spacing(0.5, 2, 0.5, 2),
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    borderRadius: '4px',
    width: '100%',
    justifyContent: 'space-between',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  elevation: {
    boxShadow: '0px 3px 11px #08135914'
  },
  overflowVisible: {
    overflow: 'visible'
  },
  '@media screen and (min-width: 380px)': {
    filterButton: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1)
    }
  },
  '@media screen and (min-width: 600px)': {
    filterButton: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      fontSize: '0.875rem'
    }
  }
}))

const LocationPopover: React.VFC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const region = useRegion()
  const router = useRouter()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'filterRegion'
  })

  return (
    <>
      <ButtonGroup
        className={`${classes.buttonGroup} ${
          popupState.isOpen ? classes.expandedButton : classes.collapsedButton
        } ${popupState.isOpen && classes.expandedButtonGroup}`}
        disableRipple
      >
        <Button
          variant='contained'
          className={`${classes.filterButton} ${classes.textButton} ${
            popupState.isOpen ? classes.expandedButton : classes.collapsedButton
          }`}
          {...bindTrigger(popupState)}
        >
          {region}
        </Button>
        <Button
          variant='contained'
          className={`${classes.filterButton} ${
            popupState.isOpen ? classes.expandedButton : classes.collapsedButton
          }`}
          {...bindTrigger(popupState)}
        >
          <WorldIcon />
        </Button>
      </ButtonGroup>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        className={classes.popover}
        PaperProps={{
          className: `${classes.elevation} ${classes.overflowVisible}`
        }}
        elevation={0}
      >
        <Paper className={classes.paper}>
          <Button
            onClick={() => {
              const query = router.query
              query.region = 'dk'
              router.push({
                pathname: router.pathname,
                query
              })
            }}
            className={classes.langMenuItem}
            endIcon={region === 'dk' && <CheckIcon />}
            disableRipple
          >
            {t('Denmark')}
          </Button>
          <Button
            onClick={() => {
              const query = router.query
              query.region = 'no'
              router.push({
                pathname: router.pathname,
                query
              })
            }}
            className={classes.langMenuItem}
            endIcon={region === 'no' && <CheckIcon />}
            disableRipple
          >
            {t('Norway')}
          </Button>
          <Button
            onClick={() => {
              const query = router.query
              query.region = 'se'
              router.push({
                pathname: router.pathname,
                query
              })
            }}
            className={classes.langMenuItem}
            endIcon={region === 'se' && <CheckIcon />}
            disableRipple
          >
            {t('Sweden')}
          </Button>
          {router.pathname.includes('news') && (
            <Button
              onClick={() => {
                const query = router.query
                query.region = 'en'
                router.push({
                  pathname: router.pathname,
                  query
                })
              }}
              className={classes.langMenuItem}
              endIcon={region === 'en' && <CheckIcon />}
              disableRipple
            >
              {t('English')}
            </Button>
          )}
        </Paper>
      </Popover>
    </>
  )
}

export default LocationPopover
