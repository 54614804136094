import React from 'react'
import { InputAdornment, IconButton, TextField, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons'
import { useTranslation } from 'next-i18next'

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  searchField: {
    height: '40px',
    overflow: 'hidden',
    borderRadius: '8px',
    boxShadow: '0px 3px 11px #08135914',
    color: theme.palette.secondary.main
  },
  inputAdornment: {},
  filledInput: {
    background: 'white',
    height: '40px',
    minWidth: '200px',
    borderBottom: 'none !important',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'white'
    },
    '&:focus-within': {
      backgroundColor: 'white'
    }
  },
  input: {
    padding: 0,
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }
}))

// Searchbox component
const SearchBox = ({ query, setQuery }) => {
  const { t } = useTranslation()
  const classes = useStyle()

  const handleChange = (event) => {
    setQuery(event.target.value)
  }

  return (
    <TextField
      autoFocus={
        !(typeof window !== 'undefined' && window.screen.width <= 1025)
      }
      className={classes.searchField}
      color='secondary'
      variant='filled'
      fullWidth
      value={query ? query : ''}
      onChange={handleChange}
      id='searchInput'
      placeholder={t('Search')}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position='start'
            variant='standard'
            className={classes.inputAdornment}
          >
            <SearchIcon color='primary' />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            {query && (
              <IconButton
                color='primary'
                aria-label='reset search'
                onClick={() => {
                  setQuery('')
                }}
                edge='end'
              >
                <CloseIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
        className: classes.filledInput,
        disableUnderline: true
      }}
      inputProps={{
        className: classes.input
      }}
    />
  )
}

export default SearchBox
