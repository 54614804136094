import React from 'react'
import { Calendar, useStaticState } from '@material-ui/pickers'
import { Paper, Popover, Button, ButtonGroup } from '@material-ui/core'
import { Close as CloseIcon, Event as EventIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import {
  usePopupState,
  bindTrigger,
  bindPopover
} from 'material-ui-popup-state/hooks'
import { useTranslation } from 'next-i18next'

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    boxShadow: '0px 3px 11px #08135914',
    borderRadius: '8px',
    width: '100%',
    border: '2px solid white'
  },
  button: {
    maxWidth: '250px',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    },
    padding: theme.spacing(1, 5),
    borderRadius: '8px',
    boxShadow: '0 3px 11px #08135914',
    fontWeight: 'bold'
  },
  filterButton: {
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    fontWeight: 'bold',
    boxShadow: 'none',
    borderRadius: '8px',
    fontSize: '0.75rem'
  },
  textButton: {
    width: '100%'
  },
  expandedButtonGroup: {
    border: `2px solid ${theme.palette.primary.main}`
  },
  expandedButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      boxShadow: 'none'
    }
  },
  collapsedButton: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
      boxShadow: 'none'
    }
  },
  active: {
    border: `2px solid ${theme.palette.primary.main}`
  },
  popover: {
    marginTop: theme.spacing(2)
  },
  paper: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px',
    minWidth: '320px',
    padding: theme.spacing(3)
  },
  calendar: {
    backgroundColor: 'white',
    borderRadius: '8px'
  },
  elevation: {
    boxShadow: '0px 3px 11px #08135914'
  },
  '@media screen and (min-width: 380px)': {
    filterButton: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1)
    }
  },
  '@media screen and (min-width: 600px)': {
    filterButton: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      fontSize: '0.875rem'
    }
  }
}))

const DatePopover = ({ date, setDate }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'filterDate'
  })

  const { pickerProps } = useStaticState({
    value: date,
    onChange: (newDate) => {
      setDate(newDate.startOf('day'))
      popupState.close()
    }
  })

  const resetDate = () => {
    setDate(undefined)
  }

  return (
    <>
      <ButtonGroup
        className={`${classes.buttonGroup} ${
          popupState.isOpen ? classes.expandedButton : classes.collapsedButton
        } ${popupState.isOpen && classes.expandedButtonGroup} ${
          date && classes.active
        }`}
        disableRipple
      >
        <Button
          variant='contained'
          className={`${classes.filterButton} ${classes.textButton} ${
            popupState.isOpen ? classes.expandedButton : classes.collapsedButton
          }`}
          {...bindTrigger(popupState)}
        >
          {t('Date')}
        </Button>
        <Button
          variant='contained'
          className={`${classes.filterButton} ${
            popupState.isOpen ? classes.expandedButton : classes.collapsedButton
          }`}
          onClick={() => (date ? resetDate() : popupState.open())}
          {...(date ? [] : bindTrigger(popupState))}
        >
          {date ? <CloseIcon onClick={resetDate} /> : <EventIcon />}
        </Button>
      </ButtonGroup>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        className={classes.popover}
        PaperProps={{
          className: classes.paper
        }}
        elevation={0}
      >
        <Paper elevation={0} className={classes.calendar}>
          <Calendar {...pickerProps} />
          {/* https://material-ui-pickers.dev/guides/static-components */}
        </Paper>
      </Popover>
    </>
  )
}

export default DatePopover
