/**
 * Specialized function for replacing all scandinavian letters with appropriate ASCII versions, and then cleaning all non-ASCII characters from a string
 * @param input String to clean for non-ASCII characters
 * @returns Cleaned string
 */
export const replaceNonASCII = (input: string): string =>
  input
    .replace(/æ/g, 'ae')
    .replace(/ø/g, 'o')
    .replace(/å/g, 'aa')
    .replace(/ä/g, 'a')
    .replace(/ö/g, 'o')
    .replace(/[^\x00-\x7F]+/g, '')

/**
 * Replaces special characters not suited for slug urls.
 * Multiple consecutive instances of them (e.g. " - ") are replaced by a single underscore.
 * @param input String to clean
 * @returns Cleaned string
 */
export const replaceSpecial = (input: string): string =>
  input
    .replace(/[?!\(\)\[\]\{\}\.,;:'"\|]/g, '')
    .trim()
    .replace(/[ \-/#&]+/g, '_')

export const cleanSlug = (input: string): string =>
  replaceSpecial(replaceNonASCII(input.trim().toLowerCase()))
