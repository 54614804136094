import { Timestamp } from '../types/types'
import { cleanSlug } from './cleanSlug'

// Adds zeros to the left side of a number and returns it as a string
const leftPad = (input: number, digits: number): string => {
  const diff = digits - input.toString().length

  if (diff > 0) {
    const pad = new Array(diff + 1).join('0')
    return `${pad}${input.toString()}`
  } else {
    return input.toString()
  }
}

export const generateConcertSlug = (
  time: Timestamp | number,
  title: string
): string => {
  let date: Date

  if (typeof time === 'number') {
    // time is unix timestamp in seconds :/
    date = new Date(time * 1000)
  } else {
    date = time.toDate()
  }

  const year = date.getFullYear()
  const month = leftPad(date.getMonth() + 1, 2)
  const day = leftPad(date.getDate(), 2)
  const hour = leftPad(date.getHours(), 2)
  const minute = leftPad(date.getMinutes(), 2)

  const slug = `${year}${month}${day}${hour}${minute}_${title}`

  return cleanSlug(slug)
}

export const generateConcertSeriesSlug = (name: string): string =>
  cleanSlug(name)

export const generateEnsembleSlug = (name: string): string => cleanSlug(name)

export const generateFestivalSlug = (start: Date, name: string): string => {
  const year = start.getFullYear()
  const month = leftPad(start.getMonth() + 1, 2)

  let slug = name

  if (!name.includes(`${year}`)) {
    slug = `${year}${month}_${name}`
  }

  return cleanSlug(slug)
}

export const generateVenueSlug = (name: string, city: string): string => {
  let slug = name

  if (!name.toLowerCase().includes(city.toLowerCase())) {
    slug = `${name}_${city}`
  }

  return cleanSlug(slug)
}

export const generateNewsPostSlug = (title: string): string => cleanSlug(title)
